import type { FC } from 'react';
import React, { Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled, css } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';
import type { MutationUpdaterFn } from 'apollo-client';
import type { DataProxy } from 'apollo-cache';

import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import CheckMarkIcon from '@atlaskit/icon/core/check-mark';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Box, xcss, Pressable, Text } from '@atlaskit/primitives';
import AkCheckCircle from '@atlaskit/icon/core/success';
import CommentAddIcon from '@atlaskit/icon/core/comment-add';

import {
	VIEW_REACTIONS_EXPERIENCE,
	DELETE_INLINE_COMMENT_EXPERIENCE,
	DELETE_PAGE_COMMENT_EXPERIENCE,
	ADD_PAGE_COMMENT_EXPERIENCE,
	ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
	ADD_PAGE_COMMENT_PUBLISH_EXPERIENCE,
	EDIT_INLINE_COMMENT_EXPERIENCE,
	EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
	EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
	startEditorExperiences,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { DisabledCommentAction } from '@confluence/comment';
import { fg } from '@confluence/feature-gating';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import type { ReactionContainerType, ReactionsCommentsQueryData } from '@confluence/reactions';
import { Reactions, ReactionsCommentsQuery, ReactionLocation } from '@confluence/reactions';
import {
	ReactionsContext,
	useCommentsContentState,
	useCommentsContentActions,
} from '@confluence/comment-context';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import {
	type ReactionsSummary,
	type CommentInlineResolveProperties,
} from '@confluence/inline-comments-queries';
import { useCommentsDataActions, CommentType } from '@confluence/comments-data';
import {
	isClipboardApiSupported,
	isIEClipboardApiSupported,
	copyToClipboard,
	copyToClipboardIE,
} from '@confluence/comments-util';
import { useSessionData } from '@confluence/session-data';
import { AnalyticsSource, ActionName } from '@confluence/comments-util/entry-points/analytics';

import { InlineCommentMode } from './enum/InlineCommentMode';
import { InlineCommentFramework } from './enum/InlineCommentFramework';
import type { CommentPermissions, InlineCommentsMode, CommentAction } from './inlineCommentsTypes';
import { ActionsContainer } from './styled-components';
import { i18n } from './inlineCommentsi18n';
import { inlineCommentExists } from './inlineCommentsCommonUtils';

const reactionBelowCommentBodyStyle = xcss({
	marginTop: 'space.0',
});

const itemStyles = css({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: token('space.075'),
});

const reactionInCommentPanelStyle = xcss({
	paddingTop: 'space.050',
	marginLeft: 'space.200',
	paddingLeft: 'space.300',
	paddingBottom: 'space.100',
});

const reactionInCommentPanelStyleV2 = xcss({
	paddingTop: 'space.050',
	paddingBottom: 'space.100',
});

const replyReactionInCommentPanelStyle = xcss({
	marginLeft: 'space.0',
	paddingLeft: 'space.0',
	paddingTop: 'space.150',
	paddingBottom: 'space.0',
});

const withLeftBorderStyle = xcss({
	borderLeft: `1.5px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
});

// As footer does not have resolvedByDangling
type CustomProps = Omit<CommentInlineResolveProperties, 'resolvedByDangling'> & {
	resolvedByDangling?: boolean;
};

type CommentActionsProps = {
	pageId: string;
	pageType: string;
	commentId: string;
	permissions: CommentPermissions;
	isReply: boolean;
	parentCommentId?: string | null;
	restrictEdit?: boolean;
	restrictDelete?: boolean;
	deleteComment?: () => void;
	editComment?: () => void;
	resolveComment?: () => void;
	numReplies?: number;
	isCommentActive?: boolean;
	mode: InlineCommentsMode;
	onClose?: () => void;
	overflowMenuPortal?: HTMLDivElement | null;
	supportedActions?: CommentAction[];
	isCommentsPanel?: boolean;
	isHovered?: boolean;
	annotationId?: string;
	resolveProperties?: CustomProps;
	reopenComment?: () => void;
	replyToComment?: (commentId: string) => void;
	isUnread?: boolean;
	inheritedReactionsData?: ReactionsSummary | null;
	reactionsCacheUpdateFn?: (
		emojiId: string,
		actionType: 'add' | 'delete',
		contentId: string,
		containerId?: string,
		cache?: DataProxy,
	) => MutationUpdaterFn<any> | void;
	commentDateUrl?: string;
	isResolvedByAnotherUser?: boolean;
	commentType: CommentType;
	hideBranchingStyle?: boolean;
	canAddComments?: boolean;
	isDeletedByAnotherUser?: boolean;
	isSelected?: boolean;
	isParentCommentOpen?: boolean;
};

type DropdownMenuContainerProps = {
	isCommentActive?: boolean;
	mode: InlineCommentsMode;
	showClose: boolean;
	enableDropdownForEditMode: boolean;
	isCommentsPanel: boolean;
	isUnread: boolean;
	canAddComments?: boolean;
};

// WS-2470: We need to override this for correct placement of disabled actions in the overflow menu
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverflowDisabledDropdownItem = styled(DropdownItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '0 !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMenuContainer = styled.span<DropdownMenuContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
    	${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles */ ''}
    position: ${(props: DropdownMenuContainerProps) =>
			props.isCommentsPanel ? 'relative' : 'absolute'};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    top: ${(props: DropdownMenuContainerProps) =>
			props.isCommentsPanel
				? '0px'
				: props.mode === 'view'
					? '-20px'
					: props.enableDropdownForEditMode
						? token('space.negative.400')
						: props.showClose
							? token('space.negative.050')
							: token('space.negative.025')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    right: ${(props: DropdownMenuContainerProps) =>
			props.isCommentsPanel
				? 'unset'
				: props.mode !== 'view'
					? props.enableDropdownForEditMode
						? token('space.300')
						: props.showClose
							? token('space.300')
							: token('space.050')
					: props.isCommentsPanel
						? !props.isUnread
							? token('space.0')
							: token('space.300')
						: token('space.500')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    visibility: ${(props: DropdownMenuContainerProps) =>
			!props.isCommentActive && props.mode === 'view-all' ? 'hidden' : 'visible'};

    button, button:hover {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    };
    & span[role="img"], > svg {
      height: 20px;
      width: 20px;
    }
  `,
);

const clickableItemStyle = xcss({
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ReplyContainer = styled.div({
	alignContent: 'center',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ReactionsContainer = styled.div<{ hasReactions: boolean; isCommentsPanel: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	display: (props) => (props.hasReactions ? 'block' : 'inline-flex'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	flexBasis: (props) => (props.hasReactions ? '100%' : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginTop: (props) =>
		!props.isCommentsPanel && !props.hasReactions ? token('space.050') : token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginLeft: (props) =>
		!props.isCommentsPanel && !props.hasReactions ? token('space.050') : token('space.0'),
});

type ActionItemProps = {
	mode?: InlineCommentsMode;
	isReply?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ActionItem = styled.li<ActionItemProps>(`
  display: inline-flex;
  flex-flow: row nowrap;
  color: ${token('color.text.subtle')};
  min-height: 24px;
  align-items: center;

  a {
    align-items: flex-end;
    color: ${token('color.text.brand')};
    flex: 2;
    text-align: right;
    font: ${token('font.body.small')};
    padding-top: ${token('space.050')};
    cursor: pointer;
  }

  & button {
    color: ${token('color.text.subtle')};
  }

  & button:focus {
    outline: 2px solid ${token('color.border.focused')};
  }

  &:not(:last-of-type):after {
    content: "•";
    color: ${token('color.text.subtle')};
    padding: 0 ${token('space.050')} 0 ${token('space.050')};
  }

  &:first-child:after {
    display: none;
  }
`);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ActionButton = styled.button(`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus {
    outline: 2px solid ${token('color.border.focused')};
    outline-offset: 2px;
  }

  :hover span {
    text-decoration: underline;
  }
`);

const pressableStyles = xcss({
	':hover': {
		textDecoration: 'underline',
	},
});

const rightAlignedActionItemsStyle = xcss({
	position: 'absolute',
	top: 'space.0',
	right: 'space.0',
	display: 'flex',
	gap: 'space.075',
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingLeft: 'space.150',
});

const hoveredBackground = xcss({
	background: token('color.background.input'),
});

const selectedBackground = xcss({
	background: token('color.background.input.hovered'),
});

const resolvedTextStyle = css({
	color: token('color.text.subtle'),
	font: token('font.heading.xxsmall'),
});

export const CommentActions: FC<CommentActionsProps> = ({
	pageId,
	pageType,
	commentId,
	permissions,
	isReply,
	parentCommentId,
	restrictEdit,
	restrictDelete,
	deleteComment,
	editComment,
	resolveComment,
	isCommentActive,
	mode,
	onClose,
	overflowMenuPortal,
	supportedActions = ['edit', 'delete', 'resolve'],
	isCommentsPanel = false,
	isHovered,
	annotationId,
	resolveProperties,
	reopenComment,
	replyToComment,
	isUnread = false,
	inheritedReactionsData,
	reactionsCacheUpdateFn,
	commentDateUrl,
	isResolvedByAnotherUser,
	commentType,
	hideBranchingStyle = false,
	canAddComments = true,
	isDeletedByAnotherUser = false,
	isSelected = false,
	isParentCommentOpen,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [showCommentActionItems, setShowCommentActionItems] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [isRemovable, setIsRemovable] = useState(false);
	const [isResolvable, setIsResolvable] = useState(false);
	const [canBeReopened, setCanBeReopened] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isReactionsEnabled } = useContext(ReactionsContext);
	const [reactionsCount, setReactionsCount] = useState(inheritedReactionsData?.reactionsCount || 0);

	const { hasContentChanged } = useCommentsContentState();
	const { resetContentChanged } = useCommentsContentActions();
	const { showModal } = useDialogs();
	const { updateReactionsSummary } = useCommentsDataActions();
	const { edition } = useSessionData();

	const isLivePage = useIsCurrentPageLive();
	const { formatMessage } = useIntl();

	const handleGqlError = (error: Error) => {
		experienceTracker.stopOnError({
			name: VIEW_REACTIONS_EXPERIENCE,
			error,
		});
	};

	const { data: reactionsData, loading: reactionsLoading } = useQuery<ReactionsCommentsQueryData>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReactionsCommentsQuery,
		{
			variables: { contentId: commentId, containerId: pageId },
			onError: handleGqlError,
			fetchPolicy: 'cache-and-network',
			// If we have the reactions from a higher component, skip the query
			skip: Boolean(inheritedReactionsData),
		},
	);

	useEffect(() => {
		// If we have the reactions from a higher component, use that count
		const count =
			(inheritedReactionsData === undefined
				? reactionsData?.comments?.nodes?.[0]?.reactionsSummary?.reactionsCount
				: inheritedReactionsData?.reactionsCount) || 0;

		setReactionsCount(count);
	}, [reactionsData, inheritedReactionsData]);

	useEffect(() => {
		// Actions are decided by the mode we're displaying
		// and the permissions available for the user
		const canEdit = Boolean(
			permissions?.isEditable && editComment && supportedActions.includes('edit'),
		);
		const canDelete = Boolean(
			permissions?.isRemovable && deleteComment && supportedActions.includes('delete'),
		);
		const canResolve = Boolean(
			permissions?.isResolvable && resolveComment && supportedActions.includes('resolve'),
		);

		// if user doesn't have resolve comment permission then they shouldn't have reopen permission too
		const hasPermissionToReopen =
			reopenComment && supportedActions.includes('reopen') && permissions?.isResolvable;

		const canReopen = Boolean(
			hasPermissionToReopen && !isReply, // only parent comments can be reopend),
		);

		// handle reopening dangling comments
		if (resolveProperties?.resolved && resolveProperties?.resolvedByDangling) {
			if (!fg('confluence_frontend_dangling_comments')) {
				setCanBeReopened(false);
			} else {
				setCanBeReopened(canReopen);
			}

			// handle reopening non-dangling comments
		} else {
			setCanBeReopened(
				Boolean(
					canReopen &&
						(resolveProperties?.resolved || isResolvedByAnotherUser) &&
						annotationId &&
						inlineCommentExists(annotationId),
				),
			);
		}

		if (mode === 'view' || mode === 'edit') {
			if (!isReply) {
				setIsResolvable(canResolve); // Parent comments are resolvable if allowed
			} else {
				setIsResolvable(false); // Replies cannot be resolved
			}

			if (isCommentsPanel) {
				const hasPermissions = canEdit || canDelete || canResolve;
				const shouldShowDropdownMenu = !!isHovered && hasPermissions;

				// Set the state for using the dropdown menu
				setShowCommentActionItems(shouldShowDropdownMenu);
			} else if (!isReply && (canEdit || canDelete || canResolve)) {
				setShowCommentActionItems(true); // Parent comments or replies outside panel can show menu
			}

			// Users can delete the replies they created
			setIsRemovable(canDelete);
			setIsEditable(canEdit);
		} else {
			setIsRemovable(canDelete);
			setIsEditable(canEdit);
			setIsResolvable(canResolve && !isReply);
		}
	}, [
		mode,
		permissions,
		isHovered,
		isReply,
		isCommentsPanel,
		supportedActions,
		deleteComment,
		editComment,
		resolveComment,
		reopenComment,
		resolveProperties?.resolvedByDangling,
		annotationId,
		resolveProperties?.resolved,
		isResolvedByAnotherUser,
	]);

	useEffect(() => {
		// If the user is on the comments panel and leaves this comment and the dropdown menu is open,
		// close the dropdown menu so they don't see it when they return
		if (isCommentsPanel && isDropdownOpen && !isHovered) {
			setIsDropdownOpen(false);
		}
	}, [isCommentsPanel, isDropdownOpen, isHovered, setIsDropdownOpen]);

	const getInlineCommentModeConstant = () => {
		switch (mode) {
			case 'view':
				return InlineCommentMode.VIEW;
			case 'edit':
				if (isLivePage) {
					return InlineCommentMode.LIVE;
				}
				return InlineCommentMode.EDIT;
		}
	};

	const handleDelete = () => {
		if (commentType === CommentType.INLINE) {
			experienceTracker.start({
				name: DELETE_INLINE_COMMENT_EXPERIENCE,
				attributes: {
					mode: getInlineCommentModeConstant(),
					framework: InlineCommentFramework.ANNOTATION_PROVIDER,
					isReply,
					source: isCommentsPanel ? AnalyticsSource.COMMENTS_PANEL : AnalyticsSource.STANDALONE,
				},
			});
		} else {
			experienceTracker.start({
				name: DELETE_PAGE_COMMENT_EXPERIENCE,
				attributes: {
					isReply,
				},
			});
		}

		showModal(CommentWarningDialog, {
			onConfirm: () => {
				deleteComment && deleteComment();
			},
			isDelete: true,
			onCancel: () => {
				// abort delete experience if dialog is closed
				experienceTracker.abort({
					name:
						commentType === CommentType.INLINE
							? DELETE_INLINE_COMMENT_EXPERIENCE
							: DELETE_PAGE_COMMENT_EXPERIENCE,
					reason: 'delete dialog closed',
				});
			},
		});
	};

	const handleEditComment = () => {
		if (commentType === CommentType.INLINE) {
			startEditorExperiences(
				/* compoundExperience */ {
					name: EDIT_INLINE_COMMENT_EXPERIENCE,
					attributes: {
						mode: getInlineCommentModeConstant(),
						framework: InlineCommentFramework.ANNOTATION_PROVIDER,
						isReply,
						type: isReply ? 'reply' : 'topLevel',
					},
				},
				/* editorLoadExperience */ {
					name: EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
				},
				/* editorPublishExperience */ {
					name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
				},
			);
		} else {
			startEditorExperiences(
				/* compoundExperience */ {
					name: ADD_PAGE_COMMENT_EXPERIENCE,
					attributes: {
						timeout: ExperienceTimeout.EDITOR_PUBLISH,
						editor: 'FABRIC',
						mode: 'editComment',
						isSingleExperience: true,
						edition,
					},
				},
				/* editorLoadExperience */ {
					name: ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
				},
				/* editorPublishExperience */ {
					name: ADD_PAGE_COMMENT_PUBLISH_EXPERIENCE,
				},
			);
		}

		editComment && editComment();
	};

	const handleReply = () => {
		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					replyToComment && replyToComment(parentCommentId || commentId);
				},
			});
		} else {
			replyToComment && replyToComment(parentCommentId || commentId);
		}
	};

	const handleCopyLink = async () => {
		const linkToComment = `${window.location.origin}/wiki${commentDateUrl}`;

		if (isClipboardApiSupported()) {
			await copyToClipboard(linkToComment);
		} else if (isIEClipboardApiSupported()) {
			await copyToClipboardIE(linkToComment);
		}
	};

	const handleResolveComment = () => {
		const doResolve = () => {
			experienceTracker.start({
				name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				id: commentId,
				attributes: {
					mode: getInlineCommentModeConstant(),
					framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				},
			});
			resolveComment && resolveComment();
		};

		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					doResolve();
				},
			});
		} else {
			doResolve();
		}
	};

	const handleReopenComment = () => {
		reopenComment && reopenComment();
	};

	const renderEditAction = (isRestricted?: boolean, isDropdownMenu?: boolean) => {
		if (isDropdownMenu) {
			return isRestricted ? (
				<OverflowDisabledDropdownItem testId="disabled-edit-comment-dropdown-action">
					<DisabledCommentAction action="edit" isReply={isReply} isInOverflowMenu />
				</OverflowDisabledDropdownItem>
			) : (
				<DropdownItem
					testId="edit-comment-dropdown-action"
					data-cy="edit-comment-action"
					onClick={handleEditComment}
				>
					<FormattedMessage {...i18n.edit} />
				</DropdownItem>
			);
		}

		return isRestricted ? (
			<DisabledCommentAction action="edit" isReply={isReply} isInOverflowMenu />
		) : (
			<ActionButton
				data-testid="edit-comment-action"
				data-cy="edit-comment-action"
				onClick={handleEditComment}
			>
				<FormattedMessage {...i18n.edit} />
			</ActionButton>
		);
	};

	const renderDeleteAction = (isRestricted?: boolean, isDropdownMenu?: boolean) => {
		if (isDropdownMenu) {
			return isRestricted ? (
				<OverflowDisabledDropdownItem testId="disabled-delete-comment-dropdown-action">
					<DisabledCommentAction action="delete" isReply={isReply} isInOverflowMenu />
				</OverflowDisabledDropdownItem>
			) : (
				<DropdownItem testId="delete-comment-dropdown-action" onClick={handleDelete}>
					<FormattedMessage {...i18n.delete} />
				</DropdownItem>
			);
		}

		return isRestricted ? (
			<DisabledCommentAction action="delete" isReply={isReply} />
		) : (
			<ActionButton data-testid="delete-comment-action" onClick={handleDelete}>
				<FormattedMessage {...i18n.delete} />
			</ActionButton>
		);
	};

	const renderReopenComment = () => {
		return (
			canBeReopened && (
				<div css={itemStyles}>
					{/* this means we're in resolved view in the comments panel */}
					{isResolvedByAnotherUser && (
						<div css={resolvedTextStyle}>{formatMessage(i18n.resolved)}</div>
					)}
					<IconButton
						icon={(iconProps) => (
							<AkCheckCircle
								{...iconProps}
								spacing="spacious"
								color={token('color.icon.success')}
								label={formatMessage(i18n.reopen)}
							/>
						)}
						label={formatMessage(i18n.reopen)}
						isTooltipDisabled={false}
						onClick={handleReopenComment}
						appearance="subtle"
						testId="reopen-comment-action"
						analyticsContext={{
							attributes: {
								name: ActionName.REOPEN_COMMENT,
								source: isCommentsPanel
									? AnalyticsSource.COMMENTS_PANEL
									: AnalyticsSource.STANDALONE,
								isDanglingComment: resolveProperties?.resolvedByDangling, // all dangling comments are inline comments
							},
						}}
					/>
				</div>
			)
		);
	};

	const renderReplyAction = () => {
		if (!isCommentsPanel && !fg('confluence_frontend_comments_panel_v2')) return;
		return (
			<ReplyContainer>
				<ActionButton
					data-testid="reply-comment-action"
					data-cy="reply-comment-action"
					onClick={handleReply}
				>
					<FormattedMessage {...i18n.replyPlaceholder} />
				</ActionButton>
			</ReplyContainer>
		);
	};

	const renderCopyButton = () => {
		return (
			<DropdownItem testId="copy-link-dropdown-action" onClick={handleCopyLink}>
				<FormattedMessage {...i18n.copyLink} />
			</DropdownItem>
		);
	};

	const renderResolveOption = () => {
		if (isCommentsPanel && isReply && fg('confluence-frontend-comments-panel')) {
			return;
		}
		return (
			<DropdownItem testId="resolve-comment-dropdown-action" onClick={handleResolveComment}>
				<FormattedMessage {...i18n.resolve} />
			</DropdownItem>
		);
	};

	const renderDropdownMenu = (editRestricted?: boolean, deleteRestricted?: boolean) => {
		// hide the dropdown if not the comments panel and does not have permissions to edit/delete/resolve
		const hasNoCommmentPermissions = !isEditable && !isRemovable && (isReply || !isResolvable);
		const shouldHideDropdownForStandalone = !isCommentsPanel && hasNoCommmentPermissions;
		const shouldHideDropdownForCommentsPanel =
			isCommentsPanel && hasNoCommmentPermissions && !fg('confluence_frontend_comments_panel_v2');
		if (shouldHideDropdownForStandalone || shouldHideDropdownForCommentsPanel) {
			return <></>;
		}
		const dropdownMenu = (
			<DropdownMenuContainer
				data-cy="comment-overflow-actions-menu"
				data-testid="comment-overflow-actions-menu"
				isCommentActive={isCommentActive}
				mode={mode}
				showClose={Boolean(onClose)}
				enableDropdownForEditMode={fg('confluence-frontend-comments-panel') || mode === 'edit'}
				isCommentsPanel={isCommentsPanel}
				isUnread={isUnread}
			>
				<DropdownMenu
					testId="comment-overflow-menu"
					isOpen={isDropdownOpen}
					placement="bottom-end"
					onOpenChange={({ isOpen, event }) => {
						event?.stopPropagation();
						setIsDropdownOpen(isOpen);
					}}
					trigger={({ triggerRef, ...props }) => (
						<IconButton
							{...props}
							testId="comment-overflow-menu-trigger"
							ref={triggerRef}
							label={formatMessage(i18n.commentActionsMenuLabel)}
							appearance="subtle"
							icon={ShowMoreHorizontalIcon}
							isTooltipDisabled={false}
							isSelected={isDropdownOpen}
							analyticsContext={{
								attributes: {
									name: 'moreActionsButton',
									source: isCommentsPanel
										? AnalyticsSource.COMMENTS_PANEL
										: AnalyticsSource.STANDALONE,
									commentType,
								},
							}}
						/>
					)}
				>
					<DropdownItemGroup>
						{isCommentsPanel && fg('confluence_frontend_comments_panel_v2') && renderCopyButton()}
						{!isCommentsPanel && isResolvable && renderResolveOption()}
						{isEditable && renderEditAction(editRestricted, showCommentActionItems)}
						{isRemovable && renderDeleteAction(deleteRestricted, showCommentActionItems)}
					</DropdownItemGroup>
				</DropdownMenu>
			</DropdownMenuContainer>
		);

		return overflowMenuPortal ? createPortal(dropdownMenu, overflowMenuPortal) : dropdownMenu;
	};

	const reactionsUpdateFn = useMemo(() => {
		if (reactionsCacheUpdateFn) {
			return reactionsCacheUpdateFn;
		}

		return inheritedReactionsData !== undefined && isCommentsPanel
			? (
					emojiId: string,
					actionType: 'add' | 'delete',
					objectId: string,
					containerId?: string,
					cache?: DataProxy,
				) =>
					updateReactionsSummary(
						emojiId,
						actionType,
						objectId,
						commentType,
						containerId,
						annotationId,
						cache,
					)
			: undefined;
	}, [
		inheritedReactionsData,
		reactionsCacheUpdateFn,
		isCommentsPanel,
		annotationId,
		commentType,
		updateReactionsSummary,
	]);
	const renderReplyButton = () => {
		return (
			<IconButton
				testId="comment-reply-button"
				label={formatMessage(i18n.replyPlaceholder)}
				appearance="subtle"
				spacing="compact"
				icon={CommentAddIcon}
				onClick={handleReply}
				isTooltipDisabled={false}
			/>
		);
	};

	const renderResolveActionButton = () => {
		return (
			<IconButton
				testId="comment-resolve-action-item"
				label={formatMessage(i18n.resolve)}
				appearance="subtle"
				spacing="compact"
				icon={CheckMarkIcon}
				onClick={handleResolveComment}
				isTooltipDisabled={false}
				css={{ padding: 0 }}
				analyticsContext={{
					attributes: {
						name: 'resolveActionsButton',
						source: isCommentsPanel ? AnalyticsSource.COMMENTS_PANEL : AnalyticsSource.STANDALONE,
						commentType,
					},
				}}
			/>
		);
	};

	const renderReactions = ({
		onlyRenderPicker = false,
		readOnly = false,
	}: { onlyRenderPicker?: boolean; readOnly?: boolean } = {}) => {
		return (
			<ReactionsContainer hasReactions={reactionsCount > 0} isCommentsPanel={isCommentsPanel}>
				<Reactions
					readOnly={readOnly}
					contentId={commentId}
					containerId={pageId}
					containerType={pageType as ReactionContainerType}
					location={
						mode === 'edit'
							? ReactionLocation.EDITOR_INLINE_COMMENT
							: ReactionLocation.INLINE_COMMENT
					}
					hidePlaceholder={reactionsCount === 0 || inheritedReactionsData !== undefined}
					reactionsData={
						inheritedReactionsData === undefined
							? reactionsData?.comments?.nodes?.[0]?.reactionsSummary
							: inheritedReactionsData
					}
					// Only provide an update function if we supply this function with data
					updateCache={reactionsUpdateFn}
					onlyRenderPicker={onlyRenderPicker}
					isSubtle={isCommentsPanel}
					showAddReactionText={false}
					customAnalyticsAttributes={{
						source: isCommentsPanel ? AnalyticsSource.COMMENTS_PANEL : AnalyticsSource.STANDALONE,
						isReply,
						mode,
					}}
				/>
			</ReactionsContainer>
		);
	};

	const renderV1ReopenComment = () => {
		return (
			<Pressable
				testId="old-reopen-comment-action"
				onClick={handleReopenComment}
				padding="space.0"
				backgroundColor="color.background.neutral.subtle"
				xcss={pressableStyles}
			>
				<Text color="color.text.subtlest" size="small">
					<FormattedMessage {...i18n.reopen} />
				</Text>
			</Pressable>
		);
	};
	const renderActionItemsOnHover = () => (
		<Box
			data-testId="comment-action-items"
			xcss={[
				rightAlignedActionItemsStyle,
				clickableItemStyle,
				isHovered && hoveredBackground,
				isSelected && selectedBackground,
			]}
		>
			<div css={itemStyles}>
				{!resolveProperties?.resolved &&
					!isResolvedByAnotherUser &&
					!isReply &&
					canAddComments &&
					renderReplyButton()}
				{isReactionsEnabled && !reactionsLoading && (
					<div>{renderReactions({ onlyRenderPicker: true })}</div>
				)}

				<div>
					{!isReply &&
						!resolveProperties?.resolved &&
						!isResolvedByAnotherUser &&
						isResolvable &&
						renderResolveActionButton()}
				</div>
				<div>{renderDropdownMenu(restrictEdit, restrictDelete)}</div>
			</div>
		</Box>
	);

	const renderStaticActionsItemsForCommentsPanel = () => {
		const isResolved = resolveProperties?.resolved || isResolvedByAnotherUser;

		return (
			<>
				{isResolved && (
					<Box
						xcss={fg('confluence_frontend_comments_panel_v2') && rightAlignedActionItemsStyle}
						testId="static-actions-items"
					>
						{fg('confluence_frontend_comments_panel_v2')
							? renderReopenComment()
							: renderV1ReopenComment()}
					</Box>
				)}
			</>
		);
	};

	const renderCommentsPanelActionItems = () => {
		const shouldRenderReplyAction =
			!resolveProperties?.resolved &&
			!isResolvedByAnotherUser &&
			!fg('confluence_frontend_comments_panel_v2');

		const commonElements = (
			<>
				{shouldRenderReplyAction && renderReplyAction()}
				{isReactionsEnabled && !reactionsLoading && reactionsCount > 0 && (
					<Box
						xcss={[
							reactionBelowCommentBodyStyle,
							isCommentsPanel && reactionInCommentPanelStyle,
							isCommentsPanel &&
								fg('confluence_frontend_comments_panel_v2') &&
								reactionInCommentPanelStyleV2,
							isCommentsPanel &&
								isReply &&
								fg('confluence_frontend_comments_panel_v2') &&
								replyReactionInCommentPanelStyle,
							isCommentsPanel &&
								!isReply &&
								!hideBranchingStyle &&
								canAddComments &&
								fg('confluence_frontend_comments_panel_v2') &&
								withLeftBorderStyle,
						]}
					>
						{renderReactions({
							readOnly: resolveProperties?.resolved || (isReply && !isParentCommentOpen),
						})}
					</Box>
				)}
			</>
		);

		// no hover state for resolved comments
		const shouldRenderHoverState = () => {
			if (isReply && !isParentCommentOpen) {
				return false;
			} else {
				return isHovered && !resolveProperties?.resolved && !isDeletedByAnotherUser;
			}
		};

		const stateSpecificElements = shouldRenderHoverState()
			? renderActionItemsOnHover()
			: renderStaticActionsItemsForCommentsPanel();

		return (
			<>
				{stateSpecificElements}
				{commonElements}
			</>
		);
	};

	const renderStandaloneCommentActions = () => {
		return (
			<ActionsContainer>
				<ActionItem />
				{!isReply && isResolvable && (
					<ActionItem mode={mode} isReply={isReply}>
						<ActionButton
							data-testid="resolve-comment-action"
							data-cy="resolve-comment-action"
							onClick={handleResolveComment}
						>
							<FormattedMessage {...i18n.resolve} />
						</ActionButton>
					</ActionItem>
				)}
				{!showCommentActionItems && isEditable && (
					<ActionItem mode={mode} isReply={isReply}>
						{renderEditAction(restrictEdit, showCommentActionItems)}
					</ActionItem>
				)}
				{!showCommentActionItems && isRemovable && (
					<ActionItem mode={mode} isReply={isReply}>
						{renderDeleteAction(restrictDelete, showCommentActionItems)}
					</ActionItem>
				)}
				{isReactionsEnabled &&
					!reactionsLoading &&
					!resolveProperties?.resolved &&
					renderReactions()}
				{!resolveProperties?.resolved ||
					// eslint-disable-next-line confluence-feature-gating/no-preconditioning
					(isCommentsPanel && fg('confluence-frontend-comments-panel') && renderReplyAction())}
			</ActionsContainer>
		);
	};

	const renderStandaloneCommentActionItems = () => {
		return (
			<>
				{!isReply && renderDropdownMenu(restrictEdit, restrictDelete)}
				{renderStandaloneCommentActions()}
			</>
		);
	};

	return (
		<Fragment>
			{isCommentsPanel ? renderCommentsPanelActionItems() : renderStandaloneCommentActionItems()}
		</Fragment>
	);
};
